import React, { useState } from 'react';
import axios from 'axios';
import './App.css';

import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import "intl-tel-input/styles";

function App() {
  const [formData, setFormData] = useState({
    first_name: '',
    surname: '',
    date_of_birth: '',
    address_line_1: '',
    address_line_2: '',
    city: '',
    postcode: '',
    mobile_number: '',
    whatsapp_number: '',
    email: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isPrivacyPolicyChecked, setIsPrivacyPolicyChecked] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handlePrivacyPolicyChange = (e) => {
    setIsPrivacyPolicyChecked(e.target.checked);
  };

  const validatePostcode = () => {
    if (formData.postcode) {
      setIsLoading(true);
      axios.get(`https://api.postcodes.io/postcodes/${formData.postcode}`)
        .then(response => {
          if (response.data.status === 200) {
            const postcodeData = response.data.result;
            setFormData({
              ...formData,
              postcode: postcodeData.postcode,
              city: postcodeData.admin_district
            });
            setErrorMessage('');
          } else {
            setFormData({
              ...formData,
              postcode: '',
              city: ''
            });
            setErrorMessage('Invalid postcode. Please try again.');
          }
          setIsLoading(false);
        })
        .catch(error => {
          console.error('There was an error fetching the address!', error);
          setFormData({
            ...formData,
            postcode: ''
          });
          setErrorMessage('Invalid postcode. Please try again.');
          setIsLoading(false);
        });
    }
  };

  const setPhoneNumber = (status, current_value, country) => {
    setFormData({
      ...formData,
      mobile_number: "+" + country.dialCode + current_value
    });
  }

  const setWhatsAppNumber = (status, current_value, country) => {
    setFormData({
      ...formData,
      whatsapp_number: "+" + country.dialCode + current_value
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isPrivacyPolicyChecked) {
      alert('Please agree to the Privacy Policy before submitting.');
      return;
    }

    // ensure mobile number field is populated
    if (!formData.mobile_number) {
      alert('Please enter a valid mobile number before submitting.');
      return;
    }

    axios.post('https://signup.stmarkyouth.uk:56211/create_user', formData)
      .then(response => {
        alert("Welcome to the St Mark's Youth Family!");
        setFormData({
          first_name: '',
          surname: '',
          date_of_birth: '',
          address_line_1: '',
          address_line_2: '',
          city: '',
          postcode: '',
          mobile_number: '',
          whatsapp_number: '',
          email: '',
        });
        setIsPrivacyPolicyChecked(false);
      })
      .catch(error => {
        console.error('There was an error recording the signup!', error);
      });
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>St Mark's Youth Sign-Up</h1>
        <form onSubmit={handleSubmit}>
          <label htmlFor="first_name">First Name</label>
          <input
            type="text"
            name="first_name"
            id="first_name"
            placeholder="First Name"
            value={formData.first_name}
            onChange={handleChange}
            required
          />

          <label htmlFor="surname">Surname</label>
          <input
            type="text"
            name="surname"
            id="surname"
            placeholder="Surname"
            value={formData.surname}
            onChange={handleChange}
            required
          />

          <label htmlFor="date_of_birth">Date of Birth</label>
          <input
            type="date"
            name="date_of_birth"
            id="date_of_birth"
            placeholder="Date of Birth"
            value={formData.date_of_birth}
            onChange={handleChange}
            required
          />

          <label htmlFor="address_line_1">Address Line 1</label>
          <input
            type="text"
            name="address_line_1"
            id="address_line_1"
            placeholder="Address Line 1"
            value={formData.address_line_1}
            onChange={handleChange}
            required
          />

          <label htmlFor="address_line_2">Address Line 2</label>
          <input
            type="text"
            name="address_line_2"
            id="address_line_2"
            placeholder="Address Line 2"
            value={formData.address_line_2}
            onChange={handleChange}
          />

          <label htmlFor="postcode">Postcode</label>
          <div className="postcode-row">
            <input
              type="text"
              name="postcode"
              id="postcode"
              placeholder="Postcode"
              value={formData.postcode}
              onChange={handleChange}
              onBlur={validatePostcode}
              required
            />
            {isLoading && <span className="loading">Loading...</span>}
          </div>
          {errorMessage && <div className="error-message">{errorMessage}</div>}

          <label htmlFor="mobile_number">Mobile Number</label>

          <IntlTelInput
            containerClassName="intl-tel-input"
            inputClassName="form-control"
            onPhoneNumberChange={setPhoneNumber}
            defaultCountry="gb"
            class="tel-input"
          />

          <label htmlFor="whatsapp_number">WhatsApp Number</label>
          <IntlTelInput
            containerClassName="intl-tel-input"
            inputClassName="form-control"
            onPhoneNumberChange={setWhatsAppNumber}
            defaultCountry="gb"
            class="tel-input"
          />

          <label htmlFor="email">Email</label>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            required
          />

          <div className="privacy-policy">
            <input
              type="checkbox"
              id="privacy-policy"
              checked={isPrivacyPolicyChecked}
              onChange={handlePrivacyPolicyChange}
              required
            />
            <label htmlFor="privacy-policy">
              I agree to the <a href="https://www.termsfeed.com/live/b36a1001-69b3-4dd4-83fe-221b9e369891" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
            </label>
          </div>

          <button type="submit" disabled={!isPrivacyPolicyChecked}>Sign Up</button>
        </form>
      </header>
    </div>
  );
}

export default App;
